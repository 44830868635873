import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import {NavLink, Route, Router} from "react-router-dom";

import Loader from "./loader"
import ReactGA from "react-ga";
import Footer from "./footer";

const Home = React.lazy(() => import("./home"));
const PetroCanada = React.lazy(() => import("./petrocanada"));
const Forte = React.lazy(() => import("./forte"));
const ContactDetails = React.lazy(() => import("./contact"));
const PrivacyPolicy = React.lazy(() => import("./privacypolicy"));
const Sitemap = React.lazy(() => import("./sitemap"));


function TabContainer(props) {
    return (
        <Typography component="div" style={{padding: 8 * 3}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper
    },
    tabLink: {
        textAlign: "center"
    }
});

class PageContent extends React.Component {
    state = {
        value: 0,
        tabs: [
            {
                value: 0,
                label: "Home",
                url: "/",
                component: () => <Loader><Home/></Loader>
            },
            {
                value: 1,
                label: "Petro-Canada",
                url: "/petro-canada/",
                component: () => <Loader><PetroCanada/></Loader>
            },
            {
                value: 2,
                label: "Forte",
                url: "/forte/",
                component: () => <Loader><Forte/></Loader>
            },

            {
                value: 3,
                label: "Contact",
                url: "/contact/",
                component: () => <Loader><ContactDetails/></Loader>
            }
        ]
    };
    updateNavigation = () => {
        const location = window.location.href;
        let urlR = "(//.*?/(.*?)/)";

        function url_match(tab) {
            return tab.url === loc;
        }

        try {
            var loc = "/" + location.match(urlR)[2] + "/";
            let value = this.state.tabs.filter(url_match)[0].value;
            this.setState({value: value});
        } catch (err) {
            this.setState({value: 0});
        }
        
        ReactGA.pageview(window.location.pathname);

    };

    componentDidMount = () => {
        this.updateNavigation();
        this.props.history.listen(() => {
            this.updateNavigation();
        });
    };

    handleChange = (event, value) => {
        this.setState({value});
    };

    render() {
        const {classes, history} = this.props;
        const {value, tabs} = this.state;

        return (
            <Router history={history}>
                <div>
                    <div className={classes.root}>
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                variant={"scrollable"}
                                scrollButtons="auto"
                            >
                                {tabs.map(tab => (
                                    <Tab
                                        className={classes.tabLink}
                                        label={tab.label}
                                        component={NavLink}
                                        key={tab.label}
                                        to={tab.url}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        {tabs.map(tab => (
                            <Route
                                path={tab.url}
                                exact={true}
                                key={tab.label}
                                component={tab.component}
                            />
                        ))}
                        {<Route
                                path={"/privacy-policy"}
                                exact={true}
                                key={"Privacy"}
                                component={() => <Loader><PrivacyPolicy/></Loader>}
                            />}
                        {<Route
                            path={"/sitemap"}
                            exact={true}
                            key={"Sitemap"}
                            component={() => <Loader><Sitemap/></Loader>}
                            />
                        }
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default withStyles(styles)(PageContent);
