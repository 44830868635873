import React, {Suspense} from "react";
import {withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const styles = theme => ({
    spinner:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh"
    }
});

class Loader extends React.Component {


    render() {
        const {classes, children, size} = this.props;

        return (

            <Suspense
                fallback={
                    <div className={classes.spinner}>
                        <CircularProgress  size={size}/>
                    </div>}
            >
                {children}
            </Suspense>
        );
    }
}

export default withStyles(styles)(Loader);
