import React from "react";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { withCookies } from "react-cookie";

class Cookiebar extends React.Component {
  state = {
    open: true,
    vertical: "bottom",
    horizontal: "right"
  };

  handleClose = () => {
    const { cookies } = this.props;
    cookies.set("hc", true, { path: "/" });
    this.setState({ open: false });
  };

  componentWillMount() {
    const { cookies } = this.props;
    const open = !cookies.get("hc");
    this.setState({ open });
  }

  render() {
    const { vertical, horizontal, open } = this.state;

    const action = (
      <Button
        onClick={this.handleClose}
        variant="outlined"
        color="primary"
        size="small"
      >
        Ok
      </Button>
    );

    return (
      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          variant="warning"
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          action={action}
          autoHideDuration={null}
          message={
            <span id="message-id">
              This website uses cookies to improve your experience.
            </span>
          }
        />
      </div>
    );
  }
}

export default withCookies(Cookiebar);
