import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";


const styles = theme => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          P.A.Z.A Trading Ltd © 2018 All Rights Reserved.
        </Typography>

          <Typography
            variant="subtitle1"
            align="center"
            color="textSecondary"
            component="p"
          >
            <Button component={Link} to="/sitemap" exact={"true"}>
              Site Map
            </Button>
            <Button component={Link} to="/privacy-policy" exact={"true"}>
              Privacy Policy
            </Button>
          </Typography>
      </footer>
    );
  }
}
export default withStyles(styles)(Footer);
