import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import AppTheme from "./components/apptheme";
import SimpleTabs from "./components/PageContent";
import Header from "./components/header";

import Cookiebar from "./components/cookies";
import {createBrowserHistory} from "history";
import ReactGA from "react-ga";

const styles = theme => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: "auto",
      marginRight: "auto"
    },
    [theme.breakpoints.down(1100 + theme.spacing.unit * 3 * 2)]: {
      width: "auto",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }
});

const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
});

class App extends Component {
  render() {
    const { classes } = this.props;
    const spinner = document.getElementById("spinner");

    if (spinner && !spinner.hasAttribute("hidden")) {
      spinner.setAttribute("hidden", "true");
    }

    return (
      <AppTheme>
        <div className={classes.layout}>
          <Header />
          <SimpleTabs history={history}/>
        </div>
        <Cookiebar />
      </AppTheme>
    );
  }
}

export default withStyles(styles)(App);
