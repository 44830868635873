import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import logo from "../logo.svg";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ReactGA from "react-ga";

const styles = theme => ({
  heading: {
    flexGrow: 1
  },
  logo: {
    height: "50px",
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  button: {
    float: "right",
    marginTop: theme.spacing.unit * 2
  }
});

class Header extends Component {

    handleCallClick = () => {
        ReactGA.event({
            category: 'Communication',
            action: 'Call',
            label: window.location.pathname,
            nonInteraction: false
        });
    };

  render() {
    const { classes } = this.props;



    return (
      <Grid container className={classes.heading}>
        <Grid item>
          <img
            src={logo}
            className={classes.logo}
            alt="P.A.Z.A Trading Ltd Logo"
          />
        </Grid>
        <Grid item xs className={classes.phoneNumberGrid}>
          <Button
            color="primary"
            href="tel:+35799454648"
            variant="outlined"
            className={classes.button}
            onClick={this.handleCallClick}
          >
            Sales: +357 99 454648
          </Button>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Header);
