import { createMuiTheme } from "@material-ui/core/styles";

const palette = {
  primary: { main: "rgb(215,0,17)" },
  secondary: { main: "#000" },
  background: { paper: "#fff", default: "#fff" }
};
const themeName = "Thunderbird Mine Shaft Fossa";

export default createMuiTheme({
  palette,
  themeName,
  typography: {
    useNextVariants: true
  }
});
